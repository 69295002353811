import React, { Component } from 'react';

const initData = {
    heading: "Vermouth, tu restaurante digital",
    content: "Carta digital, gestión de pedidos, chat privado con los clientes, listado de favoritos, localización por maps y mucho mas totalmente",
    btnText: "Get Started",
    btnTextTwo: "Get Started",
    welcomeThumb: "/img/vermouth_home.png"
}

class welcomeEight extends Component {
    state = {
        initData: {},
        aboutData: [
            {
              "id": 1,
              "linkClass": "btn btn-bordered",
              "linkClassTwo": "btn btn-bordered text-white",
              "iconClass": "icofont icofont-brand-android-robot dsp-tc",
              "iconText": "DISPONIBLE EN",
              "iconSpan": "Google Play",
              "link": "https://play.google.com/store/apps/details?id=com.ottegu.vermouth"
            },
            {
              "id": 2,
              "linkClass": "btn btn-bordered",
              "linkClassTwo": "btn btn-bordered text-white",
              "iconClass": "icofont icofont-brand-apple dsp-tc",
              "iconText": "DISPONIBLE EN",
              "iconSpan": "Apple Store",
              "link": "https://apps.apple.com/es/app/id1517306787"
            }
        ]
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-inherit h-100vh">
                <div className="shapes-container">
                    <div className="shape bg-shape" />
                </div>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-7 col-lg-6">
                            <div className="welcome-intro text-right">
                                <h1>{this.state.initData.heading}</h1>
                                <p className="my-4 welcome-intro-text">{this.state.initData.content} <span>GRATIS!!!</span></p>
                                <div className="button-group store-buttons">
                                    {this.state.aboutData.map((item, idx) => {
                                        return(
                                            <a key={`ao_${idx}`} target="_blank" href={item.link} className={item.linkClass}>
                                                <i className={item.iconClass} />
                                                <p className="dsp-tc">{item.iconText}
                                                    <br /> <span>{item.iconSpan}</span></p>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-6">
                            {/* Welcome Thumb */}
                            <div className="welcome-thumb text-center" data-aos="fade-right" data-aos-delay={500} data-aos-duration={1000}>
                                <img src={this.state.initData.welcomeThumb} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default welcomeEight;