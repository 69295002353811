import React, { Component } from 'react';

class promoTwo extends Component {
    state = {
        data: {},
        promoDataTwo: [
            {
              "id": 1,
              "promoImage": "/img/feature_2.png",
              "promoTitle": "Mensajería privada",
              "promoText": "Mensajería privada con tus clientes, para gestionar reservas, responder dudas..."
            },
            {
              "id": 2,
              "promoImage": "/img/feature_1.png",
              "promoTitle": "Gestión de pedidos",
              "promoText": "Tus clientes podrán hacerte pedidos sobre tu carta... si habilitas los pedidos, tu eliges!"
            },
            {
              "id": 3,
              "promoImage": "/img/feature_3.png",
              "promoTitle": "Carta digital",
              "promoText": "Todos tus productos, con imágenes, precios, ingredientes..."
            }
        ]
    }
    
    render() {
        return (
            <section id="benifits" className="section benifits-area ptb_100">
                <div className="container">
                    <div className="row">
                        {/* Benifits Item */}
                        {this.state.promoDataTwo.map((item, idx) => {
                            return(
                                <div key={`pt_${idx}`} className="col-12 col-sm-6 col-md-4" data-aos="fade-up">
                                    <div className="benifits-item text-center p-3">
                                        <div className="feature-icon">
                                            <img src={item.promoImage} alt="" />
                                        </div>
                                        {/* Benifits Text */}
                                        <div className="benifits-text">
                                            <h3 className="mb-2">{item.promoTitle}</h3>
                                            <p>{item.promoText}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default promoTwo;