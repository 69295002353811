import React, { Component } from 'react';


class contactSection extends Component {
    state = {
        data: {
            "heading": "Contactar",
            "headingText": "Si tienes alguna consulta o necesitas soporte para el alta de tu establecimiento contacta con nosotros y te atenderemos en la mayor brevedad posible."
        }
    }
    render() {
        return (
            <section id="contact" className="contact-area bg-gray ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-6">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {/* Contact Box */}
                            <div className="contact-box text-center">
                            {/* Contact Form */}
                            <h2>info@vermouth.app</h2>
                            <p className="form-message" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default contactSection;