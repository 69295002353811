import React, { Component } from 'react';

class footer extends Component {
    state = {
        data: {
            "image": "/img/logo-white2.png",
            "text": "Vermouth es la app con la que podrás tener tu restaurante digital de forma gratuita y accesible para los usuarios.",
            "linkText_1": "Enlaces",
            "linkText_3": "Descargas",
            "copyrightText": "Copyrights 2020 Vermouth"
          },
        iconList: [
            {
                "id": 1,
                "classname": "instagram",
                "link": "https://www.instagram.com/vermouth.app/",
                "iconClass": "fab fa-instagram"
            },
            {
              "id": 2,
              "classname": "facebook",
              "link": "https://www.facebook.com/Vermouth-102705871510280",
              "iconClass": "fab fa-facebook-f"
            }
        ],
        footerList_1: [
            {
              "id": 1,
              "text": "Inicio",
              "link": "#home"
            },
            {
              "id": 2,
              "text": "Servicios",
              "link": "#benifits"
            },
            {
              "id": 3,
              "text": "Contact",
              "link": "#contact" 
            }
        ],
        footerList_3: [
            {
              "id": 1,
              "text": "For IOS",
              "link": "https://apps.apple.com/es/app/id1517306787"
            },
            {
              "id": 4,
              "text": "For Android",
              "link": "https://play.google.com/store/apps/details?id=com.ottegu.vermouth"
            }
        ]
    }
    render() {
        return (
            <footer className="section footer-area footer-bg">
                {/* Footer Top */}
                <div className="footer-top ptb_100">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4">
                        {/* Footer Items */}
                        <div className="footer-items">
                            {/* Logo */}
                            <a className="navbar-brand" href="#">
                            <img className="logo" src={this.state.data.image} alt="" />
                            </a>
                            <p className="text-white-50 mt-2 mb-4">{this.state.data.text}</p>
                            {/* Social Icons */}
                            <div className="social-icons d-flex">
                                {this.state.iconList.map((item, idx) => {
                                    return(
                                        <a key={`sil_${idx}`} className={item.classname} target="_blank" href={item.link}>
                                            <i className={item.iconClass} />
                                            <i className={item.iconClass} />
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                        {/* Footer Items */}
                        <div className="footer-items">
                            {/* Footer Title */}
                            <h3 className="footer-title text-white mb-2">{this.state.data.linkText_1}</h3>
                            <ul>
                                {this.state.footerList_1.map((item, idx) => {
                                    return(
                                        <li key={`flo_${idx}`} className="py-2"><a className="text-white-50" href={item.link}>{item.text}</a></li>
                                    );
                                })}
                            </ul>
                        </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                        {/* Footer Items */}
                        <div className="footer-items">
                            {/* Footer Title */}
                            <h3 className="footer-title text-white mb-2">{this.state.data.linkText_3}</h3>
                            <ul>
                                {this.state.footerList_3.map((item, idx) => {
                                    return(
                                        <li key={`flo_${idx}`} className="py-2"><a className="text-white-50" target="_blank" href={item.link}>{item.text}</a></li>
                                    );
                                })}
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                    <div className="row">
                        <div className="col-12">
                        {/* Copyright Area */}
                        <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            {/* Copyright Left */}
                            <div className="copyright-left text-white-50">&copy; {this.state.data.copyrightText}</div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default footer;