import React from 'react';

import ThemeEight from "./themes/theme-eight";

function App() {
  return (
    <div>
      <ThemeEight />
    </div>
  );
}

export default App;