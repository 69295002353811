import React, { Component } from 'react';

import ScrollupSection from '../components/scrollupSection/scrollUp';
import Header from '../components/headerSection/headerOne';
import WelcomeSection from '../components/welcomeSection/welcomeEight';
import PromoSection from '../components/promoSection/promoTwo';
import ContactSection from '../components/contactSection/contact';
import FooterSection from '../components/footerSection/footer';

class ThemeEight extends Component {
    render() {
        return (
            <div className="moscow miami">
                <ScrollupSection />
                <div className="all-area">
                    <Header imageData={"/img/vermouth-logo-header.png"} />
                    <WelcomeSection />
                    <PromoSection />
                    <ContactSection />
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default ThemeEight;